<template>
    <div class="dashboard-main">
        <h1 class="dashboard-main-heading" v-html="resource[lang].heading" />

        <Button 
            class="add-button" 
            color="accent" 
            :label="resource[lang].upload_file" 
            @click="showFileUploadModal" 
        />
        
        <div class="search grid">
            <InputText :label="resource[lang].enter_search_term" v-model="search_term" />
            <Button color="primary" :label="resource[lang].search" @click="search" />
        </div>

        <div class="files">
            <File 
                v-for="(file, index) in files" 
                :key="file.id" 
                :file="file" 
                @edit="showFileEditModal(index)"
                @delete="showDeleteModal(file.id)"
            />
        </div>
        
        <Button 
            v-if="!all_files"
            class="load-more" 
            color="white" 
            :label="resource[lang].load_more" 
            @click="loadFiles" 
        />

        <DeleteModal
            v-if="showDeleteModal"
            @confirm="deleteFile"
            @cancel="cancelDeleteModal"
        />

        <FileEditModal
            v-if="showFileEditModalFlag"
            :file="edit_file"
            @update="updateFile"
            @cancel="cancelFileEditModal"
        />

        <FileUploadModal
            v-if="showFileUploadModalFlag"
            @upload="addFile"
            @cancel="cancelFileUploadModal"
        />
    </div>
</template>

<script>
import resource from '@/assets/resources/project-files-resource'

import Button from '@/components/basic/Button'
import DeleteModal from '@/components/modals/DeleteModal'
import File from '@/components/File'
import FileEditModal from '@/components/modals/FileEditModal'
import FileUploadModal from '@/components/modals/FileUploadModal'
import InputText from '@/components/basic/InputText'

import { promiseDeleteRequest, promiseGetRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button,
        DeleteModal,
        File,
        FileEditModal,
        FileUploadModal,
        InputText,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';
        
        return {
            lang: lang,

            files: [],
            file_page: 1,
            all_files: true,

            showDeleteModalFlag: false,
            delete_id: null,

            showFileEditModalFlag: false,
            edit_file: null,

            showFileUploadModalFlag: false,

            // search
            search_term: '',
            applied_search_term: '',

            resource
        }
    },
    created() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.loadFiles();
        },

        // Requests
        getFilesRequest() {
            let route = `get-files?page=${this.file_page}`;

            if (this.applied_search_term) {
                route = `${route}&search=${this.applied_search_term}`;
            }

            return promiseGetRequest(route);
        },
        deleteFileRequest() {
            return promiseDeleteRequest(`file/${this.delete_id}`);
        },

        // Actions
        showDeleteModal(id) {
            this.showDeleteModalFlag = true;
            this.delete_id = id;
            this.$store.dispatch('modal', true);
        },
        cancelDeleteModal() {
            this.showDeleteModalFlag = false;
            this.delete_id = null;
        },
        async deleteFile() {
            this.$store.dispatch('loading', true);
            await this.deleteFileRequest();
            this.$store.dispatch('loading', false);

            this.files = this.files.filter(file => file.id != this.delete_id);

            this.showDeleteModalFlag = false;
            this.delete_id = null;
        },
        showFileEditModal(index) {
            this.showFileEditModalFlag = true;
            this.edit_file = this.files[index];
            this.$store.dispatch('modal', true);
        },
        cancelFileEditModal() {
            this.showFileEditModalFlag = false;
            this.edit_file = null;
        },
        updateFile(file) {
            const index = this.files.findIndex(file => file.id == this.edit_file.id);

            this.files[index] = file;

            this.showFileEditModalFlag = false;
            this.edit_file = null;
        },
        showFileUploadModal() {
            this.showFileUploadModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelFileUploadModal() {
            this.showFileUploadModalFlag = false;
        },
        addFile(file) {
            this.files.push(file);
            this.sortFiles();
            this.showFileUploadModalFlag = false;
        },
        search() {
            this.applied_search_term = this.search_term;

            this.files = [];
            this.file_page = 1;

            this.loadFiles();
        },

        // Functions
        async loadFiles() {
            this.$store.dispatch('loading', true);
            const response = await this.getFilesRequest();
            this.$store.dispatch('loading', false);

            this.files = [...this.files, ...response.data];

            this.file_page += 1;

            if (response.data.length == 10) {
                this.all_files = false;
            } else {
                this.all_files = true;
            }
        },
        sortFiles() {
            this.files.sort(function(a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.add-button {
    margin-block-end: 1rem;
}

.files > * + * {
    margin-block-start: 1rem;
}

.load-more {
    margin-top: 1rem;
}

</style>