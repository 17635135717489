const resource = {
    en: {
        heading: 'Edit File',
        name: 'Name',
        alt_text: 'Alternative Text',
        description: 'Description',
        old_data: 'Old Properties',
        new_data: 'New Properties',
        save: 'Save',
        discard: 'Discard',
        select_language: 'Select Language'
    },
    de: {
        heading: 'Datei bearbeiten',
        name: 'Name',
        alt_text: 'Alternativ-Text',
        description: 'Beschreibung',
        old_data: 'Alte Eigenschaften',
        new_data: 'Neue Eigenschaften',
        save: 'Speichern',
        discard: 'Änderungen verwerfen',
        select_language: 'Sprache wählen'
    }
}

export default resource;