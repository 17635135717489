const resource = {
    en: {
        heading: 'Files',
        load_more: 'Load more',
        upload_file: 'Upload File',
        enter_search_term: 'Enter Search Term',
        search: 'Search',
    },
    de: {
        heading: 'Files',
        load_more: 'Mehr laden',
        upload_file: 'Datei hochladen',
        enter_search_term: 'Suchbegriff eingeben',
        search: 'Suchen',
    }
}

export default resource;