<template>
    <BaseModal ref="base_modal" @close="closeModal">
        <label class="modal-heading" v-html="resource[lang].heading" />

        <div class="icon">
            <a :href="file.public_route" target="_blank">
                <img 
                    v-if="image" 
                    :src="file.public_route" 
                    :alt="file.alt_text"
                >
                <audio controls
                    v-if="audio" 
                    :src="file.public_route" 
                >
                    Your browser does not support the <code>audio</code> element.
                </audio>
                <video controls
                    v-if="video"
                    :src="file.public_route"
                >
                    Your browser does not support the <code>video</code> element.
                </video>
                <q-icon 
                    v-if="!image && !audio && !video" 
                    class="file-icon" 
                    name="fas fa-file" 
                    color="primary" 
                    size="4rem" 
                />
            </a>
        </div>

        <label class="modal-heading-2" v-html="resource[lang].select_language" />
        <div class="q-gutter-sm">
            <q-radio 
                color="accent"
                v-for="(option, index) in $languageOptions" 
                :key="index" 
                v-model="input_lang" 
                :val="option" 
                :label="option" 
            />
        </div>

        <label class="old-data-heading modal-heading-2" v-html="resource[lang].old_data" />
        <div class="old-data">
            <InputText :label="resource[lang].name" v-model="old.name" readonly />
            <InputText :label="resource[lang].alt_text" v-model="old.alt_text[input_lang]" readonly />
            <InputText :label="resource[lang].description" v-model="old.description[input_lang]" readonly />
        </div>

        <label class="new-data-heading modal-heading-2" v-html="resource[lang].new_data" />
        <form class="new-data" @submit.prevent="saveFile">
            <InputText :label="resource[lang].name" v-model="name" required />
            <InputText :label="resource[lang].alt_text" v-model="alt_text[input_lang]" />
            <InputText :label="resource[lang].description" v-model="description[input_lang]" />

            <div class="actions flex">
                <Button color="accent" :label="resource[lang].save" submit />
                <Button color="white" :label="resource[lang].discard" @click="closeModal" />
            </div>
            
        </form>

    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/file-edit-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'

import { promisePutRequest } from '@/assets/js/axios-utilities'

export default {
    inject: ['$languageOptions'],
    props: {
        file: {
            type: Object,
            required: true,
        }
    },
    components: {
        BaseModal,
        Button,
        InputText
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
            input_lang: lang,

            old: {
                name: '',
                alt_text: '',
                description: '',
            },

            name: '',
            alt_text: '',
            description: '',

            resource
        }
    },
    computed: {
        mime_type() {
            return this.file.name;
        },
        image() {
            return this.file.mime_type.includes('image');
        },
        audio() {
            return this.file.mime_type.includes('audio');
        },
        video() {
            return this.file.mime_type.includes('video');
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.name = this.file.name;
            this.old.name = this.file.name;

            if (!this.file.alt_text || this.file.alt_text == '') {
                this.alt_text = {en: '', de: ''};
                this.old.alt_text = {en: '', de: ''};
            } else {
                this.alt_text = JSON.parse(this.file.alt_text);
                this.old.alt_text = JSON.parse(this.file.alt_text);
            }

            if (!this.file.description || this.file.description == '') {
                this.description = {en: '', de: ''};
                this.old.description = {en: '', de: ''};
            } else {
                this.description = JSON.parse(this.file.description);
                this.old.description = JSON.parse(this.file.description);
            }
        },

        // Requests
        updateFileRequest(request_body) {
            return promisePutRequest(`file-properties/${this.file.id}`, request_body);
        },

        // Actions
        async saveFile() {
            const request_body = {
                ...this.file,
                name: this.name,
                alt_text: JSON.stringify(this.alt_text),
                description: JSON.stringify(this.description),
            };
            this.$store.dispatch('loading', true);
            const response = await this.updateFileRequest(request_body);
            this.$store.dispatch('loading', false);

            this.$emit('update', response.data.file);
            this.$refs.base_modal.closeModal();
        },
        closeModal() {
            this.$emit('cancel');
            this.$refs.base_modal.closeModal();
        }
    },
    emits: ['update', 'cancel'],
}
</script>

<style lang="scss" scoped>

.actions {
    padding-block-start: 1rem;
    justify-content: space-between;
}

.icon {
    position: relative;

    height: 100%;

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
    }

    .file-icon {
        display: block;
        margin: auto;
    }
}

.new-data  > * + * {
    margin-block-start: 1rem;
}

.old-data > * + * {
    margin-block-start: 1rem;
}

</style>