const resource = {
    en: {
        heading: 'Upload File',
        click_to_select: 'Click to Select',
        file: 'file',
        choose_file: 'Please choose a file',
        upload: 'Upload',
        cancel: 'Cancel',
        name: 'Name',
        alt_text: 'Alternative Text',
        description: 'Description',
        choose_language: 'Choose Language',
        file_properties: 'File Properties'
    },
    de: {
        heading: 'Datei hochladen',
        click_to_select: 'Klicken um Auszuwählen',
        file: 'Datei',
        choose_file: 'Bitte wählen Sie eine Datei aus',
        upload: 'Hochladen',
        cancel: 'Abbrechen',
        name: 'Name',
        alt_text: 'Alternativ-Text',
        description: 'Beschreibung',
        choose_language: 'Sprache auswählen',
        file_properties: 'Dateieigenschaften'
    }
}

export default resource;